import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  cancelClaimInviteLoading,
  cancelEarnLoading, cancelMissionsLoading, cancelReferralActivityLoading, changeCompleteMissionStatus,
  claimInviteBonus, finishMission, getMissions, getReferralActivity,
  getReferralData, setGeneralMissions,
  setNewClaimInfo, setReferralActivity,
  setReferralData,
} from '../store/features/earnSlice';
import { cloudSetItem } from '../cloudStorage';
import { getUserBalanceWithItems } from '../store/features/userSlice';
import { sendGTMEvent } from '../utils';

function* getReferralsInfoSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/referral-data`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();
      const newData = {
        config: {
          achievements: data.config.achievements,
          levelUpgrade: data.config.level_upgrade,
          premium: data.config.premium,
          regular: data.config.regular,
        },
        lastCount: data.last_count,
        totalReferrals: data.total_referrals,
        totalRevenue: data.total_revenue,
      };

      yield cloudSetItem('referralData', newData, 3600 * 3);

      yield put(setReferralData(newData));
      yield put(getReferralActivity());
    } catch (e) {
      console.error(e);
      yield put({type: cancelEarnLoading.type});
    }
  }
}

function* getReferralsActivitySaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/referral-activity`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();
      yield put(setReferralActivity(data.referrals_activity));
    } catch (e) {
      console.error(e);
      yield put({type: cancelReferralActivityLoading.type});
    }
  }
}

function* getMissionsSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/missions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();
      const sortedData = data.sort((a, b) => {
        if (a.orderNumber === b.orderNumber) {
          return b.id - a.id;
        }
        return a.orderNumber - b.orderNumber;
      });
      yield put(setGeneralMissions(sortedData));
    } catch (e) {
      console.error(e);
      yield put({type: cancelMissionsLoading.type});
    }
  }
}

function* claimFriendInviteBonusSaga({payload}) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/claim-friends-count-reward`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({count: payload}),
      });
      const data = yield response.json();
      yield put(setNewClaimInfo(data.last_count));
      yield put(getUserBalanceWithItems());
    } catch (e) {
      console.error(e);
      yield put({type: cancelClaimInviteLoading.type});
    }
  }
}

function* finishAutocompleteMissionSaga({payload}) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/mission-complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({missionId: payload}),
      });
      yield put(changeCompleteMissionStatus(payload));
      yield put(getUserBalanceWithItems());
      sendGTMEvent(`complete_mission_${payload}`, 'button', 'click', 'Complete mission');
    } catch (e) {
      console.error(e);
      yield put({type: cancelClaimInviteLoading.type});
    }
  }
}

export function* referralSagaWatcher() {
  yield takeLatest([getMissions.type], getMissionsSaga);
  yield takeLatest([finishMission.type], finishAutocompleteMissionSaga);
  yield takeLatest([getReferralData.type], getReferralsInfoSaga);
  yield takeLatest([claimInviteBonus.type], claimFriendInviteBonusSaga);
  yield takeLatest([getReferralActivity.type], getReferralsActivitySaga);
}
