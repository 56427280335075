import NavBarButton from './components/NavBarButton/NavBarButton';
import s from './NavBar.module.css';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Joystick } from '../../../assets/icons/joystick.svg';
import { ReactComponent as EarnMoreSVG } from '../../../assets/icons/earn-more.svg';
import { ReactComponent as Store } from '../../../assets/icons/store.svg';
import agoIcon from '../../../assets/images/ago-cube.png';
import { ReactComponent as StakingIcon } from '../../../assets/icons/staking.svg';
import { ROUTES } from '../../../constants';
import { useSelector } from 'react-redux';

function NavBar() {
  const location = useLocation();
  const {missionsDone} = useSelector(state => state.user);
  const isActive = (path) => location.pathname === path;

  const mainIcon = (
    <div className={s.agoContainer}>
      <img src={agoIcon} alt="ago cube" className={s.agoIcon} />
    </div>
  );

  return (
    <div className={s.container}>
      <NavBarButton
        name="Launcher"
        Asset={Joystick}
        isActive={isActive('/play')}
        path={`${ROUTES.main}/${ROUTES.play}`}
      />
      <NavBarButton
        name="Earn more"
        Asset={EarnMoreSVG}
        notice={!missionsDone}
        isActive={isActive('/earn')}
        path={`${ROUTES.main}/${ROUTES.earn}`}
      />
      <NavBarButton
        customIcon={mainIcon}
        isActive={isActive('/')}
        path={ROUTES.main}
      />
      <NavBarButton
        name="Store"
        Asset={Store}
        isActive={isActive('/store')}
        path={`${ROUTES.main}/${ROUTES.store}`}
      />
      <NavBarButton
        name="Staking"
        Asset={StakingIcon}
        isActive={isActive('/staking')}
        path={`${ROUTES.main}/${ROUTES.staking}`}
      />
    </div>
  );
}

export default NavBar;
