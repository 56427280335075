import {ReactComponent as JoyStickIcon} from '../assets/icons/joystick.svg';
import {ReactComponent as RatingIcon} from '../assets/icons/rating.svg';

export const missionsTabs = [
  // 'PARTNER',
  // 'WEEKLY',
  'GENERAL'
];

export const earnPageTabs = [
  {name: 'Referral' },
  // 'Quests',
  {name:'Missions'}
];

export const storePageTabs = [
  {name:'Ago Pass'},
  {name:'Lootbox'},
  {name:'Tap Pass'}
];

export const stakingPageTabs = [
  {name:'Week'},
  {name:'Month'}
];

export const leaderboardPageTabs = [
  {name:'Game Earnings', Icon: JoyStickIcon },
  {name:'Rating', Icon: RatingIcon}
];
